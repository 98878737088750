














































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";
import { SessionDataObject, AttendeeScheduleSlot } from "@/types/interfaces";
import { formatDistance, subMinutes, differenceInSeconds } from "date-fns";

import FavoriteButton from "@/components/shared/FavoriteButton.vue";

import scheduleitemVuexModule from "@/store/vuex-modules/scheduleitem";
const scheduleitemStore = getModule(scheduleitemVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

export default Vue.extend({
    props: {
        session: {
            required: true,
            type: Object,
            default() {
                return {} as SessionDataObject;
            }
        },
        showDateTime: {
            type: Boolean
        },
        isOndemandData: {
            type: Boolean
        },
        accessor: {
            type: String,
            default: ""
        },
        company: {
            type: String,
            default: ""
        },
        isLoadingFavorites: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isLoadingFavoritesLocal: false,
            isSavingFavorite: false,
            favoriteError: false,
            showingBios: false,
            isSessionActive: false,
            isSessionOver: false,
            sessionLabelInterval: 0,
            timeLabel: ""
        };
    },
    components: {
        FavoriteButton
    },
    computed: {
        /**
         * computed
         */
        sessionId(): string {
            let id = this.session.sessionId;

            if (!id && this.session.id) {
                id = this.session.id;
            }

            return id;
        },

        isVideoSession(): boolean {
            return Boolean(
                !this.session.speakeasyType &&
                    !this.session.url &&
                    !this.session.noSession
            );
        },

        pageConfig(): Record<string, string> {
            return this.$store.getters.getPageOptions("sessions");
        },

        sessionDateTime(): Date {
            const iso = this.myStartTime;
            return new Date(iso);
        },

        sessionEarlyAccessMinutes(): Date {
            const session = this.session;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },

        myStartTime(): Date {
            const returnValue = this.session.startTime;
            return returnValue;
        },

        myEndTime(): Date {
            const returnValue = this.session.endTime;
            return returnValue;
        },

        startTimeAdjusted(): Date {
            const earlyMinutes = this.sessionEarlyAccessMinutes;
            const startTime = this.sessionDateTime;

            return subMinutes(startTime, +earlyMinutes);
        },

        isFavorited(): boolean {
            let returnValue = false;

            if (Array.isArray(this.myfavoriteSessions)) {
                const match = this.myfavoriteSessions.find(
                    (item: AttendeeScheduleSlot) => {
                        return Boolean(item.id == this.sessionId);
                    }
                );

                returnValue = Boolean(match);
            }

            return returnValue;
        },

        myfavoriteSessions(): Array<AttendeeScheduleSlot> {
            return attendeeScheduleStore.favoriteSessions;
        }
    },
    /**
     * Life cycle
     */
    created() {
        this.handleActiveSessionCheck();
        this.sessionLabelInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);
    },

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    },
    /**
     * Methods
     */

    methods: {
        handleFavoriteClick() {
            this.isSavingFavorite = true;
            const payload = {
                type: "sessions",
                id: this.session.sessionId || this.session.id
            };
            let action;

            if (this.isFavorited) {
                action = scheduleitemStore.removeScheduleItem;
            } else {
                action = scheduleitemStore.putSchedule;
            }

            action(payload)
                .then(() => {
                    this.isLoadingFavoritesLocal = true;
                    attendeeScheduleStore.getFavoriteSessions().finally(() => {
                        this.isLoadingFavoritesLocal = false;
                    });
                })
                .catch(() => {
                    this.favoriteError = true;
                })
                .finally(() => {
                    this.isSavingFavorite = false;
                });
        },

        resetFavoriteFlags() {
            this.favoriteError = false;
        },

        handleActiveSessionCheck() {
            const session = this.session;
            const startDate = this.sessionDateTime;

            this.isSessionActive = sessionHelpSessionIsActive(session);
            this.isSessionOver = sessionHelpSessionIsOver(session);

            this.timeLabel = formatDistance(window.MgServerTime, startDate);
        },

        toggleBiographies() {
            this.showingBios = !this.showingBios;
        },

        goToSession() {
            const url = this.session.url;

            if (!this.isSessionActive) return;

            if (url) {
                let isUrl = false;
                try {
                    new URL(url);
                    isUrl = true;
                } catch (error) {
                    // no need to log error
                }

                if (isUrl) {
                    window.open(url, undefined, "noopener,noreferrer");
                } else {
                    this.$router.push(url);
                }
            } else if (this.session.speakeasyType) {
                this.$router.push({
                    path: `/meeting/SESSION${this.sessionId}`
                });
            } else {
                const startTime = this.startTimeAdjusted;
                const timeSinceStart = differenceInSeconds(
                    window.MgServerTime,
                    startTime
                );

                this.$router.push({
                    name: "Session",
                    params: {
                        id: this.sessionId,
                        startVideoAt: `${timeSinceStart}`
                    }
                });
            }
        }
    }
});
