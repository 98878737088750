









































































































































































































































































































































































































































// Base
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import store from "@/store";

// Utility
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver
} from "@/services/session-helpers";

// Components
import Spinners from "@/components/utilities/Spinners.vue";
import ExhibitorSessionItem from "@/components/sessions/ExhibitorSessionItem.vue";
import TradeshowModal from "@/components/tradeshow/TradeshowModal.vue";
import MgImage from "@/components/shared/MgImage.vue";

// Types
import {
    TradeshowCompanyObject,
    TradeshowSwimlaneTier,
    MasterSessionObject,
    SessionBase,
    SessionDataArray,
    ScheduledWithObject,
    AttendeeDataObject
} from "@/types/interfaces";

import eventHub from "@/event-hub";

// Vuex modules
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

const tradeshowStore = getModule(tradeshowVuexModule);
const sessionStore = getModule(sessionVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
import countryList from "@/assets/dictionaries/country-list";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

export default Vue.extend({
    data() {
        return {
            isLoading: true,
            isSessionLoading: true,
            isSponsorEmpty: false,
            isDataLoaded: false,
            searchString: "",
            primaryCat: "",
            secondaryCat: "",
            country: "",
            localeTimezone: "",
            isSessionActive: false,
            isSessionOver: false,
            favoriteError: false,
            isLoadingFavorites: true,
            isModalOpen: false,
            companyData: {},
            comID: "" as string | undefined,
            imgPath: "" as string | undefined,
            areSessionsActive: false,

            sessionData: {
                onDemand: [],
                upcomingSessions: [],
                currentTimeISO: ""
            } as MasterSessionObject,
            showFavorite: false,
            userLocale: "",
            hasSession : true,
            headerHeight : ""
        };
    },
    components: {
        Spinners,
        ExhibitorSessionItem,
        TradeshowModal,
        MgImage
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    computed: {
        /**
         * Computed
         */

        countries(): Array<string | undefined>  {

            const countryList = this.tradeshowCompanies.flatMap((country) => {
                        return country.address?.country;
                    }).filter(list => list != undefined)

            return countryList.length ? [...new Set(countryList)].sort() : []
        },

        userInfo(): Record<string, string> {
            return store.getters.userInfo;
        },
        pageConfig(): Record<string, any> {
            return store.getters.getPageOptions("tradeshow") || {};
        },

        textHeader(): string {
            return this.pageConfig?.textHeader;
        },

        sortedUpcomingSession(): SessionDataArray {
            const upcomingSessions = this.sessionData.upcomingSessions;

            return upcomingSessions.sort((a: SessionBase, b: SessionBase) => {
                return a.startTime.localeCompare(b.startTime);
            });
        },

        tradeshowCompanies(): Array<TradeshowCompanyObject> {
            const companies = tradeshowStore.allTradeshowCompanies;

            return companies.sort(
                (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                    const curName = cur.companyName
                        ? cur.companyName
                        : cur.name
                        ? cur.name
                        : "";

                    const nextName = nxt.companyName
                        ? nxt.companyName
                        : nxt.name
                        ? nxt.name
                        : "";

                    return curName.localeCompare(nextName);
                }
            );
        },

        mappedCompanies(): Array<TradeshowCompanyObject> {
            return this.tradeshowCompanies.map(
                (company: TradeshowCompanyObject) => {
                    const newCompany = { ...company };

                    const children = this.tradeshowCompanies.filter(
                        (child: TradeshowCompanyObject) =>
                            child.parentCompanyId &&
                            child.parentCompanyId === company.companyId
                    );

                    let content = "";

                    children.forEach((child: TradeshowCompanyObject) => {
                        content += child.companyName + " ";
                    });

                    newCompany.childContent = content;

                    return newCompany;
                }
            );
        },

        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },

        homeLabel(): string {
            return this.$store.getters.homeLabel;
        },

        headerAlignment(): string {
            return this.$store.getters.getPageOptions("home").section
                .featuredSolutionProvider.labelHeaderAlignment
                ? "text-center"
                : "";
        },

        pageDecorImage(): string {
            let returnValue = "";
            if (
                this.pageConfig.decorImages &&
                this.pageConfig.decorImages.pageBackground
            ) {
                returnValue = `/bg/${this.pageConfig.decorImages.pageBackground}`;
            }
            return returnValue;
        },

        companyGroupByTier(): Array<TradeshowSwimlaneTier> {
            const tiers: Array<TradeshowSwimlaneTier> = [];

            this.pageConfig.tiers.forEach((data: TradeshowSwimlaneTier) => {
                const tierObj = {
                    ...data,
                    companies: this.companiesWithFilter.filter(
                        (item: any) => {
                            return data.level.includes(item.level as string);
                        }

                        // !item.level || item.level.includes(data.level)
                    )
                } as TradeshowSwimlaneTier;

                tiers.push(tierObj);
            });

            return tiers.filter((tier) => tier.companies.length);
        },

        companiesWithFilter(): Array<TradeshowCompanyObject> {
            let companies = this.mappedCompanies;

            if (this.searchTerm != "") {
                companies = companies.filter((item: TradeshowCompanyObject) => {
                    let term = this.searchTerm as string;
                    term = term.toLowerCase();
                    let name = item.companyName
                        ? item.companyName
                        : item.name
                        ? item.name
                        : "";

                    name = name.toLowerCase();

                    const desc = item.companyDescription
                        ? item.companyDescription.toLowerCase()
                        : "";

                    const childContent = item.childContent
                        ? item.childContent.toLowerCase()
                        : "";

                    return (
                        name.includes(term) ||
                        desc.includes(term) ||
                        childContent.includes(term)
                    );
                });
            }

            if (this.activePrimaryCat !== "") {
                companies = companies.filter((item: TradeshowCompanyObject) => {
                    return (
                        Array.isArray(item.primaryCategory) &&
                        item.primaryCategory.includes(
                            this.activePrimaryCat as string
                        )
                    );
                });
            }

            if (this.activeSecondaryCat !== "") {
                companies = companies.filter((item: TradeshowCompanyObject) => {
                    return (
                        Array.isArray(item.secondaryCategory) &&
                        item.secondaryCategory.includes(
                            this.activeSecondaryCat as string
                        )
                    );
                });
            }

            if (this.country) {
                companies = companies.filter(
                    (company: TradeshowCompanyObject) => {
                        if (this.country === company.address?.country) {
                            return company;
                        }
                    }
                );
            }

            if (this.showFavorite) {
                const fav = this.getFavoriteExhibitor();
                companies = companies.filter((item: TradeshowCompanyObject) => {
                    return fav.includes(item.companyId as string);
                });
            }

            return companies;
        },

        primaryCategories(): Array<string> {
            const items = [] as Array<string>;

            this.tradeshowCompanies.forEach((item: TradeshowCompanyObject) => {
                if (Array.isArray(item.primaryCategory)) {
                    item.primaryCategory.forEach((cat) => {
                        if (!items.includes(cat) && cat !== "") {
                            items.push(cat);
                        }
                    });
                }
            });

            items.sort();

            return items;
        },

        secondaryCategories(): Array<string> {
            const items = [] as Array<string>;

            this.tradeshowCompanies.forEach((item: TradeshowCompanyObject) => {
                if (Array.isArray(item.secondaryCategory)) {
                    item.secondaryCategory.forEach((cat) => {
                        if (!items.includes(cat) && cat !== "") {
                            items.push(cat);
                        }
                    });
                }
            });

            items.sort();

            return items;
        },

        hasFiltersApplied(): boolean {
            return (
                this.searchTerm != "" ||
                this.activePrimaryCat != "" ||
                this.activeSecondaryCat != "" ||
                this.country != ""
            );
        },

        searchTerm(): string | null | unknown {
            return this.$route?.query?.searchQuery
                ? this.$route.query.searchQuery
                : "";
        },

        activePrimaryCat(): string | null | unknown {
            return this.$route?.query?.primaryCat
                ? this.$route.query.primaryCat
                : "";
        },

        activeSecondaryCat(): string | null | unknown {
            return this.$route?.query?.secondaryCat
                ? this.$route.query.secondaryCat
                : "";
        },

        activeFilterCountry(): string | null | unknown {
            return this.$route?.query?.country ? this.$route.query.country : "";
        },

        isTradeshowRoute(): boolean {
            return this.isTradeShow();
        },

        checkTierMerged(): Array<TradeshowCompanyObject> {
            const tiers: Array<TradeshowSwimlaneTier> = [];
            const chck = this.$store.getters.getPageOptions("home");

            if (
                chck.section.featuredSolutionProvider.mergeCompanyTier?.length
            ) {
                chck.section.featuredSolutionProvider.mergeCompanyTier.forEach(
                    (data: TradeshowSwimlaneTier) => {
                        const tierObj = {
                            ...data,
                            companies: this.tradeshowCompanies.filter(
                                (item: TradeshowCompanyObject) => {
                                    const levels = data.level;
                                    if (item.level) {
                                        return levels.includes(item.level);
                                    }
                                }
                            )
                        };

                        tiers.push(tierObj);
                    }
                );
            } else {
                this.companyGroupByTier.forEach((tier) => tiers.push(tier));
            }

            return tiers
                .flatMap((item) => item.companies)
                .sort((cur: any, nxt: any) => {
                    if (cur.companyName) {
                        return cur.companyName.localeCompare(nxt.companyName);
                    }
                });
        }
    },
    methods: {
        /**
         * Methods
         */
        togglePage(){
            this.hasSession = !this.hasSession
            return this.hasSession ? '#sponsor-listing' : '#session-listing'
        },

        getFavoriteExhibitor() {
            const attendeeData = attendeeStore.attendee;
            return attendeeData.favoriteExhibitors
                ? attendeeData.favoriteExhibitors
                : [];
        },
        toggleFavorites() {
            this.showFavorite = !this.showFavorite;
        },
        isTradeShow() {
            return this.$router.currentRoute.name == "Tradeshow" ? true : false;
        },
        getCompany(companyID: string) {
            const companyName = this.tradeshowCompanies.find(
                (item: TradeshowCompanyObject) => {
                    return item.companyId === companyID ? item.companyName : "";
                }
            );

            if (companyName) {
                return companyName.companyName;
            }
        },
        getImgPath(exhibitor: TradeshowCompanyObject) {
            const logo = exhibitor?.images?.logo;
            let returnPath = "";
            if (logo && "string" === typeof logo) {
                returnPath = logo;
            } else if (exhibitor.imageLogo) {
                returnPath = exhibitor.imageLogo;
            } else {
                returnPath = `/bg/${this.pageConfig.defaultLogo}`;
            }

            return returnPath;
        },

        getImgSrcset(exhibitor: TradeshowCompanyObject) {
            let returnPath = "";
            const set = [];

            if (exhibitor?.images?.logo) {
                const urls = exhibitor.images.logo;
                const img400 = urls["400x400"];
                const img800 = urls["800x800"];

                if (img400) {
                    set.push(`${img400} 600w`);
                }

                if (img800) {
                    set.push(`${img800} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },

        getMySession(id: string) {
            const sessions: any = this.sessionData;
            if (this.sessionData) {
                return sessions.upcomingSessions.find(
                    (item: ScheduledWithObject) => {
                        return item.companyId === id;
                    }
                );
            }
        },

        activeSession() {
            return this.sessionData ? this.sessionData.upcomingSessions : [];
        },

        handleActiveSessionCheck() {
            const session: any = this.activeSession;

            this.isSessionActive = sessionHelpSessionIsActive(session);
            this.isSessionOver = sessionHelpSessionIsOver(session);
        },

        fetchExhibitorSession() {
            return new Promise<void>((resolve, reject) => {
                sessionStore
                    .getExhibitorSession()
                    .then((data) => {
                        this.sessionData = data as MasterSessionObject;
                        return resolve();
                    })
                    .finally(() => {
                        this.isSessionLoading = false;
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            });
        },

        getLogoImage(exhibitor: TradeshowCompanyObject) {
            let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
            if (!returnPath) {
                return (returnPath = `/bg/${this.pageConfig.defaultLogo}`);
            } else {
                return returnPath;
            }
        },

        updateFilterParam(term: string, param: string) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query[param] = term;

            this.$router.push({
                query: query
            });
        },
        logPageVisit() {
            if (this.isTradeshowRoute) {
                const logData = {
                    type: `SponsorLanding`
                };

                this.$store
                    .dispatch("appendLogEntry", logData)
                    .catch((err) => console.log(err));
            }
        },

        async showModal(data: TradeshowCompanyObject) {
            if (!this.isTradeshowRoute || this.pageConfig.deActivateModal) {
                this.$router.push(
                    `/tradeshow/exhibitor-details/${data.companyId}`
                );
            } else {
                this.comID = data.companyId || "";
                this.companyData = data;
                this.isModalOpen = true;
            }
        },

        closeModal() {
            this.isModalOpen = false;
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        },

        initializeFilters() {
            const query = this.$route?.query ? this.$route?.query : false;

            if (query) {
                if (query.searchQuery) {
                    this.searchString = query.searchQuery as string;
                }

                if (query.primaryCat) {
                    this.primaryCat = query.primaryCat as string;
                }

                if (query.secondaryCat) {
                    this.secondaryCat = query.secondaryCat as string;
                }

                if (query.country) {
                    this.country = query.country as string;
                }
            }
        },

        clearFilters() {
            this.searchString = "";
            this.primaryCat = "";
            this.secondaryCat = "";
            this.country = "";

            this.$router.push({
                query: {}
            });
        },

        primaryCatChange(value: string) {
            this.updateFilterParam(value, "primaryCat");
        },

        countrySelect(value: string) {
            this.updateFilterParam(value, "country");
        },

        secondaryCatChange(value: string) {
            const term = this.activeSecondaryCat !== value ? value : "";

            this.updateFilterParam(term, "secondaryCat");
        },

        async setAreSessionsActive() {
            const navItem = await this.$store.dispatch(
                "getNavItemByName",
                "Sessions"
            );

            if (navItem && navItem.active) {
                this.areSessionsActive = true;
            }
        }
    },

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);

        const promises = [
            this.fetchExhibitorSession(),
            attendeeScheduleStore.getFavoriteSessions(),
            tradeshowStore.getTradeshowCompanies(),
            attendeeStore.getAttendee({ id: this.userInfo.id })
        ];

        Promise.allSettled(promises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;

            this.getFavoriteExhibitor();
            this.activeSession();
            this.logPageVisit();
        });

        this.userLocale =
            navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : navigator.language;

        
        
    },

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    },

    mounted() {
        this.initializeFilters();
        const sessionSection = document.getElementById("session-listing") as HTMLElement;
        const sessionList = document.getElementById("session-listing-Navigation") as HTMLElement
        const sponsorList = document.getElementById("sponsor-listing-Navigation") as HTMLElement
        const header = document.getElementById("header") as HTMLElement;
        let isScreenSize = screen.width;
        const opt = {};

        const observer = new IntersectionObserver((entries, observe) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    sessionList?.classList.add("hidden");
                    sessionList?.classList.remove("block");
                    sponsorList?.classList.remove("hidden");
                    sponsorList?.classList.add("block");
               } else {
                   sessionList?.classList.remove("hidden");
                    sessionList?.classList.add("block");
                    sponsorList?.classList.remove("block");
                    sponsorList?.classList.add("hidden");
               }
                isScreenSize = screen.width
            })

        },opt);

        const screenSizeObserver = new IntersectionObserver((entries, observe) => {
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    isScreenSize = screen.width
                }
            })

        },opt);

        observer.observe(sessionSection);
        screenSizeObserver.observe(sponsorList);
        if(isScreenSize < 780){
            this.headerHeight = getComputedStyle(header).height;
        }else {
            this.headerHeight = ''
        }
    }
});
